export default {
    toJson(params) {
        let arr = params.split("&");
        let json = {};
        for (let item of arr) {
            item = item.split("=");
            json[item[0]] = item[1];
        }
        return json;
    },
    formatDate(format, date = new Date()) {
        if (!format) {
            return;
        }
        if (date && (typeof date).toUpperCase() == "STRING") {
            date = new Date(date)
        }
        let resultDate = format;
        resultDate = resultDate.replace('yyyy', date.getFullYear())
        resultDate = resultDate.replace('MM', (date.getMonth() + 1).toString()[1] ? date.getMonth() + 1 : "0" + (date.getMonth() + 1))
        resultDate = resultDate.replace('dd', (date.getDate()).toString()[1] ? date.getDate() : "0" + date.getDate())
        return resultDate;
    }
}