import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import { Toast } from 'vant'
import util from './utils/util'
import console from './utils/console'
import request from './api/index'
import moment from 'moment'

Vue.config.productionTip = false
Vue.use(Vant);
Vue.prototype.$http = request
Vue.prototype.$toast = Toast
Vue.prototype.$util = util
Vue.prototype.console = console
Vue.prototype.moment = moment

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
