<template>
  <div class="stat" v-if="id">
    <div style="width: 100%; display: flex">
      <van-field v-model="params.workTime" center readonly clearable placeholder="选择日期" @click="openDate">
        <template #button>
          <i v-if="params.workTime.length > 0" class="van-icon van-icon-clear van-field__clear" @click="clearDate"></i>
        </template>
      </van-field>
    </div>
    <div v-for="(item, index) in members" :key="index" :class="`card ${item.flag}`" @click="showDaily(item)">
      {{ item.id.replace(/[a-z]/g, '') }}
      <!-- {{ index }} -->
    </div>
    <van-popup v-model="showDatePicker" position="bottom" :style="{ height: '40%' }">
      <van-datetime-picker v-model="currentDate" type="date" title="选择年月日" :min-date="minDate" :max-date="maxDate"
        @confirm="confirmDate" />
    </van-popup>
  </div>
</template>

<script>
import querystring from "querystring";
export default {
  name: "Statics",
  data() {
    return {
      id: "",
      // keyword: "eWFuZ2hhbw==",
      keyword: "d3lj",
      showDatePicker: false,
      currentDate: new Date(),
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      params: {
        userId: "YangHao",
        limit: 30,
        page: 1,
        workTime: this.moment().format('YYYY-MM-DD'),
      },
      members: ['BanJiaJun', 'HeZhiLin', 'LiYuFeng', 'MoWenWu', 'PengDongWen', 'SunJing', 'WeiYongChong', 'YangHao', 'ZhangGuiYuan']
        .map((e) => { return { id: e, flag: null } })
    };
  },
  created() {
    let json = this.$route.query;
    this.console.log("JSON", json);
    if (json.id) {
      this.console.log("ID", json.id);
      if (btoa(json.id) === this.keyword) {
        this.id = json.id
        this.getInfo();
      }
    }
  },
  methods: {
    confirmDate(date) {
      this.params.workTime = this.$util.formatDate("yyyy-MM-dd", date);
      this.showDatePicker = false;
      this.getInfo()
    },
    openDate() {
      if (this.isClear) {
        return;
      }
      this.showDatePicker = true;
    },
    clearDate() {
      this.isClear = true;
      this.params.date = "";
      setTimeout(() => {
        this.isClear = false;
      }, 50);
    },
    getInfo() {
      let params = querystring.stringify(this.params);
      if (params) params = "?" + params;
      this.$http
        .get("/dailyReport/queryList" + params)
        .then((res) => {
          let list = res.data.data.items;
          this.console.log("LIST", list)
          this.members.forEach(item => {
            let flag = list.some(e => e.userId.toLowerCase() == item.id.toLowerCase())
            this.console.log("FLAG", flag)
            item['flag'] = flag
          })
        })
    },
    showDaily({ id }) {
      this.$router.push({ path: '/home', query: { id } })
    }
  },
};
</script>

<style scoped>
.stat {
  width: 100%;
  height: 100%;
  min-height: 300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-around;
}

.card {
  display: flex;
  width: 30%;
  height: 30%;
  flex-shrink: 0;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  font-weight: bold;
}

.card.true {
  background: rgb(160, 243, 160);
}

.card.false {
  background: rgb(240, 159, 159);
}
</style>