<template>
  <div class="home">
    <div>
      <div class="card search">
        <div style="width: 100%; display: flex">
          <van-field
            v-model="form.date"
            center
            readonly
            clearable
            placeholder="选择日期"
            @click="openDate"
          >
            <template #button>
              <i
                v-if="form.date.length > 0"
                class="van-icon van-icon-clear van-field__clear"
                @click="clearDate"
              ></i>
            </template>
          </van-field>
          <van-field v-model="form.limit" center clearable placeholder="条数">
          </van-field>
          <van-button type="primary" @click="getInfo">查询</van-button>
        </div>
      </div>
      <div v-for="(param, indexs) in datas" :key="indexs" class="card">
        <div class="title" @click="nameOrId = !nameOrId">
          {{ param.workTime }}
        </div>
        <div class="subTitle">{{ nameOrId ? param.name : param.userId }}</div>
        <div
          class="content"
          v-for="(item, index) in param.dailyReportDetails"
          :key="index"
        >
          <div class="proName">
            <span style="flex: 1">{{ item.projectName }}</span>
            <span>{{ item.jobTime }}</span>
          </div>
          <div v-for="(itm, idx) in item.jobContent.split('\n')" :key="idx">
            {{ itm }}
          </div>
        </div>
      </div>
    </div>
    <van-popup
      v-model="showDatePicker"
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="confirmDate"
      />
    </van-popup>
    <van-overlay
      :custom-style="{ backgroundColor: 'transparent' }"
      :show="showLoading"
    >
      <van-loading style="margin-top: 60vh" color="rgb(255 96 96)" />
    </van-overlay>
  </div>
</template>

<script>
import querystring from "querystring";
export default {
  name: "Home",
  data() {
    return {
      isClear: false,
      nameOrId: false,
      form: {
        name: "",
        page: 1,
        limit: null,
        date: "",
      },
      showDatePicker: false,
      showLoading: false,
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      datas: [],
    };
  },
  watch: {},
  created() {
    let json = this.$route.query;
    this.console.log("JSON", json);
    if (json.id) {
      this.console.log("ID", json.id);
      this.form.name = json.id;
      this.getInfo();
    }
  },
  methods: {
    confirmDate(date) {
      this.form.date = this.$util.formatDate("yyyy-MM-dd", date);
      this.showDatePicker = false;
    },
    openDate() {
      if (this.isClear) {
        return;
      }
      this.showDatePicker = true;
    },
    clearDate() {
      this.isClear = true;
      this.form.date = "";
      setTimeout(() => {
        this.isClear = false;
      }, 50);
    },
    getInfo() {
      let name = this.form.name;
      this.console.log("NAME", name);
      if (!name) {
        this.$toast("请输入用户");
        return;
      }
      let params = querystring.stringify({
        userId: this.form.name,
        limit: this.form.limit ? this.form.limit : 30,
        page: this.form.page,
        workTime: this.form.date,
      });
      if (params) params = "?" + params;
      this.console.log("PARAMS", params);
      this.showLoading = true;
      this.console.log("URL", "/dailyReport/queryList" + params);
      this.$http
        .get("/dailyReport/queryList" + params)
        .then((res) => {
          res = res.data;
          this.form.page = 1;
          this.datas = res.data.items;
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.home {
  width: 100%;
  height: 100%;
  /* font-size: calc(100vw / 25); */
}
.card {
  box-sizing: border-box;
  text-align: left;
  margin-bottom: 10px;
  /* padding: 15px 20px; */
  border-radius: 3px;
  background-color: rgb(255 255 255);
  overflow: hidden;
}

.title {
  box-sizing: border-box;
  padding: 10px 20px;
  background-color: #8ed761;
  font-weight: bold;
  color: #ffffff;
}

.subTitle {
  box-sizing: border-box;
  padding: 10px 20px 0px 12px;
  font-weight: bold;
}

.content {
  box-sizing: border-box;
  padding: 10px 20px;
}

.content .proName {
  font-weight: bold;
  color: #c70000;
  display: flex;
  justify-content: space-between;
}
</style>
<style>
.search .van-cell {
  padding: 0 0 0 8px !important;
}
</style>